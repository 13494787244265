import React from "react";

import "./../scss/base.scss";
import "./../scss/layout.scss";
import styles from "./index.module.scss";

import { Link } from "gatsby";
import { motion } from "framer-motion";




const HomePage = () => {
  return (
    
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key="home"
      className={styles.pageContainer}
    >
      <h1 className={styles.headerPrimary}>Isaac Farciert</h1>
      <h3 className={styles.headerSecondary}>Software developer based in NYC</h3>

      <div className={styles.btnsRow}>
        <Link to="/development"><button type="button" className={styles.btnP}>See work</button></Link>
        <Link to="/contact"><button type="button" className={styles.btnS}>Contact</button></Link>
      </div>
    </motion.div>

  )
}

export default HomePage;